import React, { useState, useEffect } from 'react';

// Image paths for all goalkeeper attributes
const imagePaths = {
    figureColor: {
      'Silver': 'color_silver.jpg',
      'Light grey': 'color_grey.jpg',
      'Neon orange': 'color_orange.jpg',
      'Neon green': 'color_green.jpg',
    },
    rodType: {
      'Light': 'carbon.jpg',
      'Standard': 'carbon.jpg',
    },
    handleType: {
      'Airplane red': 'handle_airplane_red.jpg',
      'Airplane red v2': 'handle_airplane_red_v2.jpg',
      'Airplane grey': 'handle_airplane_grey.jpg',
      'Hexagonal 15mm red': 'handle_hex_15mm_red.jpg',
      'Hexagonal 15mm grey': 'handle_hex_15mm_grey.jpg',
      'Hexagonal 20mm red': 'handle_hex_20mm_red.jpg',
      'Hexagonal 20mm grey': 'handle_hex_20mm_grey.jpg',
    }
};
  
const Goalkeeper = ({ onAddItem }) => {
  const [figureColor, setFigureColor] = useState('Light grey');
  const [rodType, setRodType] = useState('Standard');
  const [rodTypeOptions, setRodTypeOptions] = useState([
    { value: 'Standard', label: 'Standard (~23g with carbon rod)' },
  ]);
  const [handleType, setHandleType] = useState('Airplane grey');
  const [handleTypeOptions, setHandleTypeOptions] = useState([
    { value: 'Airplane red v2', label: 'Airplane red' },
    { value: 'Airplane grey', label: 'Airplane grey' },
    { value: 'Hexagonal 15mm red', label: 'Hexagonal 15mm red' },
    { value: 'Hexagonal 15mm grey', label: 'Hexagonal 15mm grey' },
    { value: 'Hexagonal 20mm red', label: 'Hexagonal 20mm red' },
    { value: 'Hexagonal 20mm grey', label: 'Hexagonal 20mm grey' },
  ]);

  useEffect(() => {
    // Derive rodTypeOptions and handleTypeOptions based on figureColor.
    let newRodTypeOptions;
    let newHandleTypeOptions;
    if (figureColor === 'Silver') {
      newRodTypeOptions = [
        { value: 'Light', label: 'Light (~15g with carbon rod)' }
      ];
      if (rodType === 'Light') {
        newHandleTypeOptions = [
          { value: 'Airplane red', label: 'Airplane red' },
        ];
      } else {
        newHandleTypeOptions = [
          { value: 'Airplane red', label: 'Airplane red' },
          { value: 'Airplane grey', label: 'Airplane grey' },
          { value: 'Hexagonal 15mm red', label: 'Hexagonal 15mm red' },
          { value: 'Hexagonal 15mm grey', label: 'Hexagonal 15mm grey' },
          { value: 'Hexagonal 20mm red', label: 'Hexagonal 20mm red' },
          { value: 'Hexagonal 20mm grey', label: 'Hexagonal 20mm grey' },
        ];       
      } 
    } else {
      newRodTypeOptions = [
        { value: 'Standard', label: 'Standard (~23g with carbon rod)' }
      ];
      newHandleTypeOptions = [
        { value: 'Airplane red v2', label: 'Airplane red' },
        { value: 'Airplane grey', label: 'Airplane grey' },
        { value: 'Hexagonal 15mm red', label: 'Hexagonal 15mm red' },
        { value: 'Hexagonal 15mm grey', label: 'Hexagonal 15mm grey' },
        { value: 'Hexagonal 20mm red', label: 'Hexagonal 20mm red' },
        { value: 'Hexagonal 20mm grey', label: 'Hexagonal 20mm grey' },
      ];  
    }
    setRodTypeOptions(newRodTypeOptions);
    setHandleTypeOptions(newHandleTypeOptions); 
    // If the selected option is no longer available, choose the first one.  
    if (!newRodTypeOptions.some(option => option.value === rodType)) {
      setRodType(newRodTypeOptions[0].value);
    }
    if (!newHandleTypeOptions.some(option => option.value === handleType)) {
      setHandleType(newHandleTypeOptions[0].value);
    }
  }, [figureColor, rodType, handleType]);

  return (
    <div>
      <label htmlFor="quantity">Quantity:</label><br />
      <input type="number" id="quantity" min="1" defaultValue="1" /><br /><br />

      <div className="selection figure-color-selection"> 
        <div>
          <label htmlFor="figureColor">Figure colour:</label><br />
          <select id="figureColor" value={figureColor} onChange={e => setFigureColor(e.target.value)}>
            <option value="Silver">Silver</option>
            <option value="Light grey">Light grey</option>
            <option value="Neon orange">Neon orange</option>
            <option value="Neon green">Neon green</option>
          </select>
        </div>

        {/* Display the image based on the selected color */}
        {figureColor && (
          <div><img src={imagePaths.figureColor[figureColor]} alt={`Turtle Goalkeeper in ${figureColor}`} /></div>
        )}
      </div>
      <br />

      <div className="selection rod-type-selection"> 
        <div>
          <label htmlFor="rodType">Rod:</label><br />
          <select id="rodType" value={rodType} onChange={e => setRodType(e.target.value)}>
            {rodTypeOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      
        {/* Display the Rod image based on the selected type */}
        {rodType && (
          <div><img src={imagePaths.rodType[rodType]} alt={`Rod Type: ${rodType}`} /></div>
        )}
      </div>
      <br />

      <div className="selection handle-type-selection"> 
        <div>
          <label htmlFor="handleType">Handle:</label><br />
          <select id="handleType" value={handleType} onChange={e => setHandleType(e.target.value)}>
            {handleTypeOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        {/* Display the Handle image based on the selected type */}
        {handleType && (
          <div><img src={imagePaths.handleType[handleType]} alt={`Handle type: ${handleType}`} /></div>
        )}
      </div>
      <br />

      <button onClick={() => 
        onAddItem({ 
          quantity: parseInt(document.getElementById('quantity').value) || 1, 
          figureColor, 
          rodType, 
          handleType 
        }) 
      }>
        Add to Cart
      </button>
    </div>
  );
};

export default Goalkeeper;
